body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

video {
  max-width: 100%;
}

button {
  font-size: 36px;
}

thead {
  background-color: lightblue;
}

.m-1 {
  margin: 1rem;
}
