.badge-container {
  position: relative;
}

.badge {
  border-radius: 8px;
  padding-left: 2px;
  padding-right: 2px;
  min-width: 16px;
  height: 16px;
  position: absolute;
  background: #0c71c3;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: -5px;
  color: white;
  font-size: 11px;
}
