.brand-selector {
  border: 1px solid lightgrey;
  width: 118px;
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-selector img {
  max-width: 110px;
  max-height: 110px;
}

.brand-selector--is-selected {
  width: 114px;
  height: 114px;
  border-width: 3px;
  border-color: red;
}
